
import Vue from 'vue';
import { required, email } from 'vuelidate/dist/validators.min';

import theme from 'Theme';
import NoData from 'UI/NoData.vue';
import Button from 'Control/Button.vue';
import TextInput from 'Control/TextInput.vue';
import {
    activateUser, cancelUser,
    getMembersList,
    inviteMember,
    SET_EDITING_USER_EMAIL,
    suspendUser,
} from 'Store/v2/CorporateTeamMembers';
import SubuserResponse from 'Entities/userLoginHistory/SubuserResponse';
import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';

type Data = {
    theme: typeof theme;
    email: string;
    hasEmailError: boolean;
};

type Methods = {
    invite: () => void;
    setEmail: (data: string) => void;
    getStatusColor: (data: 'InvitationSent' | 'KycRequired' | 'Active' | 'Suspended') => string;
    edit: (data: string) => void;
    suspend: (data: string) => void;
    activate: (data: string) => void;
    cancel: (data: string) => void;
};

type Computed = {
    membersList: SubuserResponse[];
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Icon,
        TextInput,
        Button,
        NoData,
        Guid,
    },
    data() {
        return {
            theme,
            email: '',
            hasEmailError: false,
        };
    },
    validations() {
        return {
            email: {
                required,
                email,
            },
        };
    },
    computed: {
        membersList() {
            return this.$store.state.CorporateTeamMembers.membersList;
        },
    },
    methods: {
        async activate(id) {
            await this.$store.dispatch(activateUser({
                id,
            }));
        },
        async cancel(email) {
            await this.$store.dispatch(cancelUser({
                email,
            }));
        },
        async suspend(id) {
            await this.$store.dispatch(suspendUser({
                id,
            }));
        },
        async edit(email) {
            this.$store.commit(SET_EDITING_USER_EMAIL(email));
            await this.$router.push('/profile/subusers-edit').catch(() => { /* navigation error */ });
        },
        async invite() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.hasEmailError = true;
                return;
            }
            await this.$store.dispatch(inviteMember(this.email));
            this.setEmail('');
        },
        setEmail(email) {
            this.email = email;
            this.hasEmailError = false;
        },
        getStatusColor(status) {
            switch (status) {
                case 'InvitationSent': {
                    return 'yellow';
                }
                case 'KycRequired': {
                    return 'yellow';
                }
                case 'Active': {
                    return 'green';
                }
                case 'Suspended': {
                    return 'red';
                }
                default: {
                    return 'yellow';
                }
            }
        },
    },
    async mounted() {
        if (!this.$store.state.CorporateTeamMembers.initialized) {
            await this.$store.dispatch(getMembersList(undefined));
        }
    },
});
